import React, { useState } from "react";
import {
    Button,
    Container,
    Grid,
    InputLabel,
    Link,
    OutlinedInput,
    TextField,
} from "@mui/material";
import Expiration from "../input/Expiration";
import Password from "../input/Password";
import { uploadFile } from "../../api";
import ErrorMessage from "../ErrorMessage";
import { TailSpin } from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";
import { generateLink } from "../../utils/generateLink";

const File = () => {
    const [file, setFile] = useState("");
    const [password, setPassword] = useState("");
    const [expireIn, setExpireIn] = useState(null);
    const [link, setLink] = useState("");
    const [error, setError] = useState("");
    const [downloadCount, setDownloadCount] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(null);
    const [clientLink, setClientLink] = useState(generateLink(8))

    const handleChange = (event) => {
        setExpireIn(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const inputStyle = {
        width: "100%",
    };

    const fileUpload = async (event) => {
        event.preventDefault();
        try {
            if (
                !file ||
                !expireIn ||
                (!downloadCount && expireIn !== "Burn after read")
            ) {
                setError("Please fill all fields !");
                setTimeout(() => {
                    setError("");
                }, 3000);
            } else {
                setUploading(true);
                const formData = new FormData();
                formData.append("clientLink", clientLink);
                formData.append("password", password);
                formData.append(
                    "burnAfterRead",
                    expireIn === "Burn after read" ? true : false
                );
                formData.append(
                    "expiration",
                    expireIn !== "Burn after read" ? expireIn : ""
                );
                formData.append("limit", downloadCount);
                formData.append("file", file);
                const token = localStorage.getItem("token")
                const response = await uploadFile(formData, token, setUploadPercentage);
                if (response.status === 200) {
                    setLink(response.data.link);
                } else {
                    setError(response.error.message);
                    setTimeout(() => {
                        setError("");
                    }, 2000);
                }
                setUploadPercentage(null);
                setUploading(false);
            }
        } catch (error) {
            setUploading(false);
            console.log(error.message);
        }
    };

    return (
        <Container display="flex">
            <Grid container spacing={2} style={{ margin: "auto" }}>
                <Grid item xs={12}>
                    <InputLabel id="file">File</InputLabel>
                    <OutlinedInput
                        labelId="file"
                        type="file"
                        style={{ ...inputStyle }}
                        onChange={handleFileChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Password
                        value={password}
                        onChange={setPassword}
                        inputStyle={inputStyle}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel id="file">Link</InputLabel>
                    <OutlinedInput
                        labelId="link"
                        type="text"
                        style={{ ...inputStyle }}
                        value={clientLink}
                        onChange={(e) => setClientLink(e.target.value.replace(/\s/g, ''))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Expiration
                        value={expireIn}
                        onChange={handleChange}
                        inputStyle={inputStyle}
                    />
                </Grid>
                {expireIn !== "Burn after read" && (
                    <Grid item xs={12}>
                        <InputLabel id="download-count">Download limit</InputLabel>
                        <TextField
                            lebelId="download-count"
                            variant="outlined"
                            value={downloadCount}
                            onChange={(e) => setDownloadCount(e.target.value)}
                            style={{ ...inputStyle }}
                            placeholder="Enter download limit count"
                            type="number"
                        />
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12}>
                        <ErrorMessage error={error} color={"red"} textAlign={"center"} />
                    </Grid>
                )}

                {uploadPercentage && (
                    <Grid item xs={12}>
                        <ProgressBar completed={uploadPercentage} />
                    </Grid>
                )}

                {link ? (
                    <Grid item xs={12}>
                        <Link href={link} target="_blank">
                            {link}
                        </Link>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={(e) => fileUpload(e)}
                            style={{ width: "100px" }}
                        >
                            {uploading ? (
                                <TailSpin
                                    visible={uploading}
                                    height="30"
                                    width="20"
                                    color="#fff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            ) : (
                                "Upload"
                            )}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default File;
