import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TabbedContent from './components/Tabs';
import Header from './components/Header';
import { Box, Container } from '@mui/material';
import Download from './components/download/Download';
import Login from './components/Login';
import { authenticate } from './api';
import { TailSpin } from 'react-loader-spinner';
import backgroundImage from "./assets/images/background.jpg"

function App() {
  const [authenticated, setAuthencticated] = useState(false);
  const [loading, setLoading] = useState(true)

  const authentication = async () => {
    const res = await authenticate(localStorage.getItem("token"))
    if (res.status === 200) {
      setAuthencticated(true)
    } else {
      setAuthencticated(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    authentication()
  }, [authenticated])

  return (
    <Router>
      {authenticated && <Header />}
      <Container 
      // style={{
      //   backgroundImage: `url(${backgroundImage})`, // Specify the path to your background image
      //   backgroundSize: "cover", // Cover the entire container with the background image
      //   backgroundRepeat: "no-repeat", // Do not repeat the background image
      //   minHeight: "100vh", // Ensure the container covers the entire viewport height
      // }}
      >
        {loading ?
          <Box display={"flex"}>
            <TailSpin
              visible={loading}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ margin: "auto" }}
              wrapperClass=""
            />
          </Box> :
          <>
            <Routes>
              <Route path="*" element={authenticated ? <TabbedContent /> : <Login />} />
              <Route path="/:path" element={<Download />} />
            </Routes>
          </>}
      </Container>
    </Router>

  );
}


export default App;

