export const BASE_URL = "https://filetally.link";
export const VERSION = "/api/v1";

export const ENDPOINTS = {
    uploadFile: "/upload-file",
    uploadText: "/upload-text",
    download: "/download",
    getFile: "/getFile",
    downloadText: "/download-text",
    login: "/login",
    auth: "/authenticate",
    getVisitors: "/getVisitors",
    getApps: "/getApps",
    edit: "/edit",
}