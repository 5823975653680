import { useState } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Container, TextField, Typography, Box } from "@mui/material";
import { getVisitors } from "../../api";
import { TailSpin } from "react-loader-spinner";
import ErrorMessage from "../ErrorMessage";
import getLocalDate from "../../utils/getLocalDate";

const Visitors = () => {
    const [searchString, setSearchString] = useState("");
    const [visitors, setVisitors] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const [linkDetails, setLinkDetails] = useState(null)
    const [isRefreshing, setIsRefreshing] = useState(false)

    const search = async () => {
        try {
            if (!searchString) {
                setError("Please enter link !")
            } else {
                setLoading(true)
                setIsRefreshing(true)
                const res = await getVisitors(searchString, localStorage.getItem("token"))
                if (res.status === 200) {
                    setVisitors(res.data.visitors)
                    setLinkDetails(res.data.linkDetails)
                } else {
                    setError(res.error.message)
                }
                setLoading(false)
                setIsRefreshing(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {!visitors ? (
                <Container>
                    <TextField
                        value={searchString}
                        id="outlined-basic"
                        label="Link"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        onChange={(e) => setSearchString(e.target.value)}

                    />
                    {
                        error && <ErrorMessage error={error} color={"red"} textAlign={"center"} />
                    }
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, pt: 2, pb: 2 }}
                        onClick={() => search()}
                        margin="normal"
                    >
                        {loading ? (
                            <TailSpin
                                visible={loading}
                                height="30"
                                width="20"
                                color="#fff"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        ) : (
                            "Search"
                        )}
                    </Button>
                </Container>
            ) : (
                <TableContainer component={Paper}>
                    <Box display={"flex"} justifyContent={"end"}>
                        <Typography style={{
                            marginRight: "10px", marginTop: "5px", marginBottom: "5px", fontWeight: "bold"
                        }}>{searchString || visitors[0].link}</Typography>
                        <Button style={{ marginRight: "40px" }} onClick={() => { search() }}>

                            {
                                isRefreshing ? <TailSpin
                                    visible={loading}
                                    height="20"
                                    width="20"
                                    color="#c0efff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /> : "refresh"
                            }


                        </Button>
                    </Box>

                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">IPv4/IPv6</TableCell>
                                <TableCell align="center">User Agent</TableCell>
                                <TableCell align="center">Visit Time</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Expire In/Expired At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visitors.map((visitor) => (
                                <TableRow
                                    key={visitor.time}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="center">{visitor.ip}</TableCell>
                                    <TableCell align="center">{visitor.agent}</TableCell>
                                    <TableCell align="center">{getLocalDate(visitor.visitTime)}</TableCell>
                                    <TableCell align="center">{visitor.linkStatus ? "Expired" : "Active"}</TableCell>
                                    <TableCell align="center">{(visitor.expiredAt || visitor.expireIn) ? getLocalDate(visitor.expiredAt || visitor.expireIn) : "Burn after read"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
export default Visitors;
