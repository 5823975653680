import { Typography } from '@mui/material'
import React from 'react'

const ErrorMessage = ({ error, color, textAlign }) => {
    return (
        <>
            <Typography color={color} textAlign={textAlign}>
                {error}
            </Typography>

        </>
    )
}

export default ErrorMessage
