import { Container, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import { BASE_URL } from '../config'

const ErrorPage = ({ error, color, textAlign }) => {
    return (
        <Container display="flex">
            <Grid container spacing={2} style={{ margin: 'auto' }}>
                <Grid item xs={12}>
                    <Typography variant='h4' color={color} textAlign={textAlign} style={{ margin: "auto" }}>{error}</Typography>
                </Grid>
            </Grid>
        </Container >
    )
}

export default ErrorPage
