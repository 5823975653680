import React, { useState } from 'react'
import { IconButton, InputAdornment, InputLabel, TextField } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Password = ({ value, onChange, inputStyle }) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <>
            <InputLabel id="password">Password</InputLabel>
            <TextField
                lebelId="passwo
                rd" variant='outlined'
                value={value} onChange={(e) => onChange(e.target.value)}
                style={{ ...inputStyle }}
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>)
                }}

            />
        </>
    )
}

export default Password
