import React from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'


const Expiration = ({ value, onChange, inputStyle }) => {
    return (
        <>
            <InputLabel id="expiration">Expiration</InputLabel>
            <Select
                labelId="expiration"
                id="demo-simple-select"
                label="Age"
                value={value}
                onChange={onChange}
                style={{ ...inputStyle }}
            >
                <MenuItem value={"Burn after read"}>Burn after read</MenuItem>
                <MenuItem value={"2 days"}>2 days</MenuItem>
                <MenuItem value={"5 days"}>5 days</MenuItem>
                <MenuItem value={"7 days"}>7 days</MenuItem>
            </Select>

        </>
    )
}

export default Expiration
