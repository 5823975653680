import {
    Button,
    Container,
    Grid,
    InputLabel,
    Link,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { download, downloadText, getFile } from "../../api";
import { useParams } from "react-router-dom";
import { MagnifyingGlass, TailSpin } from "react-loader-spinner";
import ErrorMessage from "../ErrorMessage";
import Password from "../input/Password";
import ErrorPage from "../ErrorPage";

const Download = () => {
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true);
    const [initialError, setInitialError] = useState("");
    const [fileName, setFileName] = useState("");
    const [type, setType] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [text, setText] = useState("");
    const [error, setError] = useState("");
    const [downloadDone, setDownloadDone] = useState(false);

    const { path } = useParams();

    const downloadFile = async (event) => {
        event && event.preventDefault();
        try {
            setDownloading(true);
            const response = await download(path, encodeURIComponent(password));
            if (response.status === 200) {
                const name = response.headers?.filename;
                const path = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = path;
                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                setDownloadDone(true);
            } else {
                setError(
                    "Oops, the file may no longer exist or the password may be incorrect."
                );
            }
            setTimeout(() => {
                setError("");
            }, 2000);
            setDownloading(false);
        } catch (error) {
            setDownloading(false);
            console.log(error.message);
        }
    };

    const textDownload = async (event) => {
        event && event.preventDefault();
        try {
            setDownloading(true);
            const response = await downloadText(path, encodeURIComponent(password));
            if (response.status === 200) {
                setText(response.data.text);
            } else {
                setError(response.error.message);
            }
            setDownloading(false);
            setTimeout(() => {
                setError("");
            }, 2000);
        } catch (error) {
            setDownloading(false);
            console.log(error.message);
        }
    };

    const inputStyle = {
        width: "100%",
    };

    const checkFile = async () => {
        try {
            const response = await getFile(path);
            if (response.status === 200) {
                setFileName(response.data.name);
                if (response.data.redirect) {
                    window.location.href = response.data.redirect;
                } else {
                    setType(response.data.type);
                    if (!response.data.protected) {
                        response.data.type === "text"
                            ? await textDownload()
                            : await downloadFile();
                    }
                    setLoading(false);
                }
            } else {
                if (response.error.message === "Request aborted") {
                    setInitialError("downloading...");
                } else {
                    setInitialError(response.error.message);
                }
                setLoading(false);

            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        checkFile();
    }, []);

    return (
        <Container display="flex">
            {!downloadDone ? (
                <Grid container spacing={2} style={{ margin: "auto" }}>
                    {loading ? (
                        // <MagnifyingGlass
                        //     visible={loading}
                        //     height="80"
                        //     width="80"
                        //     ariaLabel="magnifying-glass-loading"
                        //     wrapperStyle={{ margin: "auto" }}
                        //     wrapperClass="magnifying-glass-wrapper"
                        //     glassColor="#c0efff"
                        //     color="#e15b64"
                        // />
                        <Typography variant='h4' color={"inherit"} textAlign={"center"} style={{ margin: "auto" }}>
                            Downloading file ...
                        </Typography>
                    ) : (
                        <>
                            {initialError ? (
                                <Grid item xs={12}>
                                    <ErrorPage
                                        error={initialError}
                                        color={"black"}
                                        textAlign={"center"}
                                    />
                                </Grid>
                            ) : text ? (
                                <Grid item xs={12}>
                                    <InputLabel id="text">Text</InputLabel>
                                    <OutlinedInput
                                        labelId="text"
                                        multiline
                                        value={text}
                                        rows={5}
                                        style={{ ...inputStyle }}
                                        placeholder="your text"
                                        contentEditable={false}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <Password
                                            value={password}
                                            onChange={setPassword}
                                            inputStyle={inputStyle}
                                        />
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <ErrorMessage
                                                error={error}
                                                color={"red"}
                                                textAlign={"center"}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            onClick={(e) => {
                                                type === "text" ? textDownload(e) : downloadFile(e);
                                            }}
                                            style={{ width: "100px" }}
                                        >
                                            {downloading ? (
                                                <TailSpin
                                                    visible={downloading}
                                                    height="20"
                                                    width="20"
                                                    color="#4fa94d"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            ) : (
                                                "download"
                                            )}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            ) : (
                <Grid container spacing={2} style={{ margin: "auto" }}>
                    <Typography
                        variant="h4"
                        color={"inherit"}
                        textAlign={"center"}
                        style={{ margin: "auto" }}
                    >
                        File Downloaded
                    </Typography>
                </Grid>
            )}
        </Container>
    );
};

export default Download;
