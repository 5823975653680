import axios from "axios"
import { BASE_URL, ENDPOINTS, VERSION } from "../config"

export const login = async (body) => {
    try {
        const res = await axios.post(`${BASE_URL}${VERSION}${ENDPOINTS.login}`, body)
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const authenticate = async (token) => {
    try {
        const res = await axios.get(`${BASE_URL}${ENDPOINTS.auth}?token=${token}`)
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const uploadFile = async (body, token, setUploadPercentage) => {
    try {
        const res = await axios.post(`${BASE_URL}${VERSION}${ENDPOINTS.uploadFile}`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "x-auth-token": token
            },
            onUploadProgress: (progressEvent) => {
                let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percent)
                setUploadPercentage(percent)
            }
        })
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.response.status)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const uploadText = async (body, token) => {
    try {
        const res = await axios.post(`${BASE_URL}${VERSION}${ENDPOINTS.uploadText}`, body, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token
            }
        })
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const download = async (link, password) => {
    try {
        const res = await axios.get(`${BASE_URL}${VERSION}${ENDPOINTS.download}?link=${link}&password=${password}`, {
            headers: {
                "Content-Disposition": "attachment",
                "Content-Type": "application/json",
                Accept: "application/octet-stream",
            },
            responseType: "arraybuffer",
        })
        return res
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const downloadText = async (link, password) => {
    try {
        const res = await axios.get(`${BASE_URL}${VERSION}${ENDPOINTS.downloadText}?link=${link}&password=${password}`)
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const getFile = async (link) => {
    try {
        const res = await axios.get(`${BASE_URL}${VERSION}${ENDPOINTS.getFile}?link=${link}`)
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        if (error.message === "Request aborted") {
            return {
                status: error.response ? error.response.status : 500,
                error: { message: "Request aborted" }
            }
        }
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const getVisitors = async (link, token) => {
    try {
        const res = await axios.get(`${BASE_URL}${VERSION}${ENDPOINTS.getVisitors}?link=${link}`, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token
            }
        })
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        if (error.message === "Request aborted") {
            return {
                status: error.response ? error.response.status : 500,
                error: { message: "Request aborted" }
            }
        }
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}

export const getApps = async (token) => {
    try {
        const res = await axios.get(`${BASE_URL}${VERSION}${ENDPOINTS.getApps}`, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token
            }
        })
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }
    }
}

export const edit = async (body, token) => {
    try {
        const res = await axios.post(`${BASE_URL}${VERSION}${ENDPOINTS.edit}`, body, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": token
            }
        })
        return { status: res.status, data: res.data }
    } catch (error) {
        console.log(error.message)
        return {
            status: error.response ? error.response.status : 500,
            error: error.response ? error.response.data : { message: "Network Error" }
        }

    }
}